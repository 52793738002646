export const COLORS = ["blue", "magenta", "cyan", "autumn", "yellow", "brown", "maroon", "supportive-3", "pink", "purple", "green", "red", "black"];

export const getColorHex = (cssVariable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVariable).replace(" ", "");
};

export const getColorsHex = () => {
  return COLORS.map((color) => {
    return getColorHex(`--color-${color}-500`);
  });
};

export const getColorNamesRepeating = (n: number) => {
  const a: string[] = [];

  let i = 0;

  for (let j = 0; j < n; j++) {
    if (i >= COLORS.length) {
      i = 0;
    }

    a.push(COLORS[i]);

    i++;
  }

  return a;
};
